import { Component } from 'vue-property-decorator'

import { SET_META } from 'booksprout-app'

import WebsiteCard from 'src/components/WebsiteCard.vue'
import WebsiteTeamMember from 'src/components/WebsiteTeamMember.vue'
import { BaseMixin } from 'src/components/mixins/base'

interface TeamMember {
  avatar: string
  avatarMobile: string
  name: string
  role: string
  linkInstagram?: string
  linkLinkedIn?: string
  linkTwitter?: string
  linkWebsite?: string
  bio: string
}

@Component({
  components: {
    WebsiteCard,
    WebsiteTeamMember
  }
})
export default class WebsiteAboutPage extends BaseMixin {
  teamMembers: TeamMember[] = []

  created () {
    const title = 'About'
    const canonicalUrl = window.location.origin + this.websitePaths.about

    void this.$store.dispatch(SET_META, {
      title: title,
      ogTitle: title,
      ogUrl: canonicalUrl,
      ogImage: window.location.origin + '/cdn/website/honest-reviews.png',
      canonicalUrl
    })

    this.teamMembers.push({
      avatar: '/cdn/website/chris-leippi.png',
      avatarMobile: '/cdn/website/chris-leippi-mobile.png',
      name: 'Chris Leippi',
      role: 'Founder/CEO',
      linkLinkedIn: '',
      bio: 'Chris began self-publishing in late 2013 and managed to make a living off of it rather quickly. Using those funds, he\'s travelled throughout the world. He dislikes doing anything more than once and will automate almost everything that\'s possible. That\'s what\'s behind most of the features on Booksprout!'
    }, {
      avatar: '/cdn/website/allan-gaunt.png',
      avatarMobile: '/cdn/website/allan-gaunt-mobile.png',
      name: 'Allan Gaunt',
      role: 'Lead Developer',
      linkLinkedIn: 'https://www.linkedin.com/in/allan-gaunt',
      bio: 'Allan is a keen programmer with over 15 years of experience in the ecommerce / software industry. Programming is both a job and a hobby for Allan with him also contributing to open source projects. With a firm vision on the end user but also the companies bottom line, Allan is driven to make a stable platform.'
    }, {
      avatar: '/cdn/website/victor-berbel.png',
      avatarMobile: '/cdn/website/victor-berbel-mobile.png',
      name: 'Victor Berbel',
      role: 'UI/UX Designer',
      linkInstagram: 'https://www.instagram.com/victor.berbel/',
      linkLinkedIn: 'https://www.linkedin.com/in/victorberbel',
      bio: 'Before being a designer Victor wanted to be the next Rodney Mullen but due to the circumstances of a stolen skateboard, he got afraid of leaving the house to skate, and to get out of boredom he discovered design and fell in love with it. Now he is a Senior UI/UX Designer and also a UX mentor at an online course.'
    }, {
      avatar: '/cdn/website/tomas-jakelis.png',
      avatarMobile: '/cdn/website/tomas-jakelis-mobile.png',
      name: 'Tomas Jakelis',
      role: 'Front-End Developer',
      linkLinkedIn: 'https://www.linkedin.com/in/tomas-jakelis',
      bio: 'Tomas is a passionate frontend web developer with over 15 years of experience, who loves to take on challenges and deliver the best. He adores UI effects, animations and creating intuitive, user-first experiences. Tomas enjoys learning new technologies and sharing findings with his colleagues.'
    }, {
      avatar: '/cdn/website/virginie-carmichael.png',
      avatarMobile: '/cdn/website/virginie-carmichael-mobile.png',
      name: 'Virginie Carmichael',
      role: 'Operations Manager',
      linkLinkedIn: '',
      bio: 'Virginie had a brief career as a romance author before she decided to focus on her web design business. Keeping everything neat and organized has always been a passion of hers, so she was a perfect fit to join the Booksprout team. She also runs Novely, a company that provides managed websites to self-published authors.'
    }, {
      avatar: '/cdn/website/michelle-lynn.png',
      avatarMobile: '/cdn/website/michelle-lynn-mobile.png',
      name: 'Michelle Lynn',
      role: 'Customer Support',
      linkLinkedIn: '',
      bio: 'Michelle Lynn has had a long career as a romance and fantasy author, publishing more than seventy books under four pen names. Her attention to detail and passion for helping other authors made Booksprout a good fit. She joined the team as a way to shift some of her focus and anxiety from her own books, and now gets to chat with other authors and many readers on a daily basis.'
    }, {
      avatar: '/cdn/website/rossana-corniel.png',
      avatarMobile: '/cdn/website/rossana-corniel-mobile.png',
      name: 'Rossana Corniel',
      role: 'Head of Marketing',
      linkLinkedIn: '',
      bio: 'Rossana combines her enthusiasm for books with a strong background in marketing to lead the marketing and communications initiatives at Booksprout. Starting her career as a U.S. Air Force photographer, she advanced to senior corporate roles, representing major brands such as DHL, LVMH, Red Bull, and Snickers. Also an author under various pen names, Rossana now brings her diverse experience into crafting marketing strategies at Booksprout.'
    })
  }
}
